import React, { useEffect } from "react"
import { default as NextHead } from "next/head"
import { initGoogleTagManager } from "@/lib/google-tag-manager"
import { usePathData, useAppContext } from "@/lib/contexts/appContext"
import { useTracking } from "@/lib/hooks/useTracking"

export default function Head() {
  const { head } = usePathData()
  const { meta } = useAppContext()

  useEffect(() => {
    const { gtmContainerId } = meta
    if (gtmContainerId) {
      initGoogleTagManager(gtmContainerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useTracking()

  return (
    <NextHead>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {head &&
        head.map((meta, index) => {
          const Tag = meta.tag
          const attrs = meta.attrs

          if (meta.tag === "title") {
            return <Tag key={index}>{attrs.innerText}</Tag>
          }

          if (meta.tag === "script") {
            return <Tag key={index} type="text/javascript" src={attrs.src} />
          }

          if (meta.tag === "meta" && attrs.name === "robots") {
            return (
              <Tag
                key={index}
                name={attrs.name}
                content={Object.values(attrs.content).join(", ")}
              />
            )
          }

          if (
            typeof attrs.content === "object" &&
            attrs?.property === "og:images"
          ) {
            const { ...content } = attrs
            const [url, props] = Object.entries(content?.content)[0]
            return (
              <React.Fragment key={index}>
                <meta property="og:image" content={url} />
                <meta property="og:image:width" content={props.width} />
                <meta property="og:image:height" content={props.height} />
                <meta property="og:image:secure_url" content={props.url} />
              </React.Fragment>
            )
          }

          return <Tag key={index} {...attrs} />
        })}
    </NextHead>
  )
}
