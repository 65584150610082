import { useEffect } from "react"
import { usePathData } from "@/lib/contexts/appContext"
import { getProps } from "../component"
import { pushDataLayer } from "../helpers/tagManager"

const allowedEvents = ["view_item_list", "view_item"]

export const useTracking = () => {
  const { path, props } = usePathData()
  const { tracking } = getProps(props, "object") || {}

  useEffect(() => {
    if (!tracking) {
      return
    }
    Object.keys(tracking)
      .filter((key) => allowedEvents.includes(key))
      .map((key) => {
        if (tracking[key].length === 0) {
          return
        }

        if (key === "view_item_list") {
          pushDataLayer(
            {
              event: key,
              ecommerce: {
                [key]: {
                  items: [...tracking[key]]
                }
              }
            },
            true
          )
        } else {
          pushDataLayer(
            {
              event: key,
              ecommerce: {
                [key]: [...tracking[key]]
              }
            },
            true
          )
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])
}
