import { useState } from "react"
import { parse } from "next-useragent"
import InApp from "detect-inapp"
import {
  useCopy,
  usePathData,
  usePropsObject,
  useMeta
} from "@/lib/contexts/appContext"
import { templateMapping } from "@/lib/templateMapping"
import { fetchContent } from "@/lib/api/fetchContent"
import NotFound from "@/components/base/notFound"

import Popup from "./popup/popup"
import Header from "./header/header"
import Footer from "./footer/footer"
import Sidebar from "@/components/blocks/theme/campaign-sidebar/sidebar"
import { useEffect } from "react"

export function Layout() {
  const { theme, sidebarContent } = usePropsObject()
  const [isCustom, setCustom] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [content, setContent] = useState([])
  const meta = useMeta()
  const pathData = usePathData()
  const copy = useCopy()

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      let { isInApp } = new InApp(window.navigator.userAgent)
      const { hasBankID } = meta
      const ua = parse(window.navigator.userAgent)
      const os = ua.os.toLowerCase()
      const browser = ua.browser.toLowerCase()

      // We add this because for some reason the isInApp check is not working on Android Chrome
      if (os === "android" && browser === "chrome") {
        isInApp = false
      }

      if (hasBankID && isInApp && os === "android") {
        setCustom(true)
        setInProgress(true)

        fetchContent("inAppAndroid").then((response) => {
          setInProgress(false)
          setContent(response.content ?? [])
        })
      }
    }
    return () => {
      isMounted = false
      setCustom(false)
      setContent([])
      setInProgress(false)
    }
  }, [meta])

  const Custom = templateMapping["custom"]
  const Template = templateMapping[pathData.template] ?? NotFound
  const hideFooter = theme?.hideFooter ?? false
  const hasSidebar = pathData.template === "campaign" && Boolean(sidebarContent)

  return (
    <>
      <Popup data={copy?.general?.popUp} />
      <Header hasSidebar={hasSidebar} />
      {!isCustom && <Template />}
      {isCustom && <Custom content={content} loading={inProgress} />}
      {!hideFooter && <Footer hasSidebar={hasSidebar} />}
      {hasSidebar && <Sidebar block={sidebarContent} />}
    </>
  )
}
