import fetchAPI from "./fetchAPI"

export async function fetchContent(context) {
  const { fetchContent } = await fetchAPI(
    `query($context: contentContext!) {
        fetchContent(context: $context) {
          status
          content
        }
      }`,
    {
      variables: { context }
    }
  )

  return fetchContent
}
